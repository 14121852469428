<template>
  <div>
    <base-modal
      v-model:show="show"
      :title="'Edit Customer'"
      size="lg"
    >

      <template v-slot:modal-content>
        <span class="text text_italic required__text">*Required Fields</span>
        <div class="row pb-2">
          <Form
            v-slot="{ errors }"
            ref="editForm"
            autocomplete="off"
            class="form form_col"
            id="edit_contact"
            @submit="handleContact"
          >

            <div class="col-12 col-md-6">
              <h4 class="titlePage titlePage_subtitle titlePage_green">Customer type</h4>
              <label class="form__label">
                <div class="form__wrapRadio">
                  <label class="form__label form__label_row">
                    <input
                      v-model="customerToBeEdited.type"
                      class="form__radio"
                      hidden
                      name="type"
                      type="radio"
                      value="Individual"
                    >
                    <span class="form__radio_icon"/>
                    <span class="form__labelTitle">Individual</span>
                  </label>
                  <label class="form__label form__label_row">
                    <input
                      v-model="customerToBeEdited.type"
                      class="form__radio"
                      hidden
                      name="type"
                      type="radio"
                      value="Business"
                    >
                    <span class="form__radio_icon"/>
                    <span class="form__labelTitle">Business</span>
                  </label>
                </div>
              </label>

              <!-- Individual customer form inputs starts -->
              <div v-show="customerToBeEdited.type === 'Individual'">
                <label class="form__label">
                  <span class="form__labelTitle">First Name*</span>
                  <Field v-model="customerToBeEdited.name"
                         :rules="{required:customerToBeEdited.type === 'Individual', max:128}"
                         class="form__input" label="first name"
                         name="name"
                         placeholder="First Name*"
                         type="text"
                  />
                  <span class="form__error">{{ errors.name }}</span>
                </label>
                <label class="form__label">
                  <span class="form__labelTitle">Last Name*</span>
                  <Field v-model="customerToBeEdited.last_name" :rules="{max:128}"
                         class="form__input"
                         label="last name"
                         name="last_name"
                         placeholder="Last Name*"
                         type="text"
                  />
                  <span class="form__error">{{ errors.last_name }}</span>
                </label>
              </div>
              <!-- Individual customer form inputs ends -->

              <!-- Business customer form inputs starts -->
              <div v-show="customerToBeEdited.type === 'Business'">
                <label class="form__label">
                  <span class="form__labelTitle">Business Name*</span>
                  <Field v-model="customerToBeEdited.business_name"
                         :rules="{required:customerToBeEdited.type === 'Business', max:128}"
                         class="form__input"
                         label="business name" name="business_name"
                         placeholder="Business Name*"
                         type="text"
                  />
                  <span class="form__error">{{ errors.business_name }}</span>
                </label>
                <label class="form__label">
                  <span class="form__labelTitle">Trading Name*</span>
                  <Field v-model="customerToBeEdited.trading_name"
                         :rules="{required:customerToBeEdited.type === 'Business', max:128}"
                         class="form__input"
                         label="trading name" name="trading_name"
                         placeholder="Trading Name*"
                         type="text"
                  />
                  <span class="form__error">{{ errors.trading_name }}</span>
                </label>

                <label class="label-switch switch-success">
                  <input id="status" v-model="sameAsBusinessName"
                         class="switch switch-bootstrap status" name="status" type="checkbox"
                         value="1">
                  <span class="lable"> Same as business name</span></label>

              </div>
              <!-- Business customer form inputs ends -->


              <!-- Address section starts -->

              <h4 class="titlePage titlePage_subtitle titlePage_green">Address</h4>

              <label class="form__label ">
                <span class="form__labelTitle">Address*</span>
                <Field as="textarea" v-model="customerToBeEdited.address"
                          :rules="{required: true, max: 255}"
                          class="form__input" name="address"
                          placeholder="Address*"
                          rows="2"
                >
                </Field>
                <span class="form__error">{{ errors.address }}</span>
              </label>

              <label class="form__label ">
                <span class="form__labelTitle">Suburb*</span>
                <Field v-model="customerToBeEdited.suburb" :rules="{max: 128}"
                       class="form__input"
                       name="suburb"
                       placeholder="Suburb*"
                       type="text"
                />
                <span class="form__error">{{ errors.suburb }}</span>
              </label>


              <label class="form__label ">
                <span class="form__labelTitle">Postcode*</span>
                <Field v-model="customerToBeEdited.postcode"
                          :rules="'digits:4|valid_au_postcode'"
                          class="form__input"
                          v-maska="'####'" name="postcode"
                          placeholder="Postcode*"
                          type="text"
                />
                <span class="form__error">{{ errors.postcode }}</span>
              </label>

              <label class="form__label ">
                <span class="form__labelTitle">State*</span>
                <select v-model="customerToBeEdited.state"
                        class="form__input form__select"
                        name="state"
                >
                  <option :value="undefined" disabled hidden selected>State*</option>
                  <option
                    v-for="(item, index) in state"
                    :key="index"
                    :value="item.state_id"
                  >{{ item.name }}
                  </option>
                </select>

              </label>

              <label v-if="customerToBeEdited.type === 'Business'"
                     class="label-switch switch-success">
                <input id="status" v-model="sameAsAboveBusinessAddress"
                       class="switch switch-bootstrap status" name="status" type="checkbox"
                       value="1">
                <span class="lable"> Billing address is same as above</span></label>
              <!-- Address section ends -->

              <!--Billing Address section starts -->
              <transition name="fade">
                <div v-show="customerToBeEdited.type === 'Business' && !sameAsAboveBusinessAddress">
                  <h4 class="titlePage titlePage_subtitle titlePage_green pt-2">Billing Address</h4>

                  <label class="form__label ">
                    <span class="form__labelTitle">Address*</span>
                    <Field as="textarea" v-model="billing_address.address"
                              :rules="{required: customerToBeEdited.type === 'Business' && !sameAsAboveBusinessAddress, max: 255}"
                              class="form__input"
                              label="address"
                              name="billing_address"
                              placeholder="Address*"
                              rows="2"
                    >
                </Field>
                    <span class="form__error">{{ errors.billing_address }}</span>
                  </label>

                  <label class="form__label ">
                    <span class="form__labelTitle">Suburb*</span>
                    <Field v-model="billing_address.suburb" :rules="{max: 128}"
                           class="form__input" name="suburb"
                           placeholder="Suburb*"
                           type="text"
                    />
                    <span class="form__error">{{ errors.suburb }}</span>
                  </label>


                  <label class="form__label ">
                    <span class="form__labelTitle">Postcode*</span>
                    <Field v-model="billing_address.postcode"
                           :rules="'digits:4|valid_au_postcode'" class="form__input"
                           name="postcode"
                           placeholder="Postcode*"
                           type="text"
                    />
                    <span class="form__error">{{ errors.postcode }}</span>
                  </label>

                  <label class="form__label ">
                    <span class="form__labelTitle">State*</span>
                    <select v-model="billing_address.state"
                            class="form__input form__select"
                            name="state"
                    >
                      <option disabled hidden selected value="">State*</option>
                      <option
                        v-for="(item, index) in state"
                        :key="index"
                        :value="item.state_id"
                      >{{ item.name }}
                      </option>
                    </select>

                  </label>
                </div>
              </transition>
              <!--Billing Address section ends -->

            </div>

            <div class="col-12 col-md-6">
              <h4 class="titlePage titlePage_subtitle titlePage_green">Contact details</h4>

              <label class="form__label">
                <span class="form__labelTitle">E-mail</span>
                <Field v-model="customerToBeEdited.email" :rules="'required|email'"
                       class="form__input"
                       name="email"
                       placeholder="E-mail"
                       type="text"
                />

                <span class="form__error">{{ errors.email }}</span>
              </label>

              <label class="form__label">
                <span class="form__labelTitle">Mobile Number*</span>
                <Field v-model="customerToBeEdited.phone"
                          :rules="{required: true, digits_without_whitespace: 10, min: 6}"
                          class="form__input"
                          label="mobile number"
                          v-maska="'## ## ### ###'"
                          name="phone"
                          placeholder="Mobile Number*"
                />
                <span class="form__error">{{ errors.phone }}</span>
              </label>

              <label class="form__label">
                <span class="form__labelTitle">Landline Number</span>
                <Field v-model="customerToBeEdited.land_line"
                          :rules="{digits_without_whitespace: 10, min: 6}"
                          class="form__input"
                          label="landline number"
                          v-maska="'## ## ### ###'"
                          name="land_line"
                          placeholder="Landline Number"
                />
                <span class="form__error">{{ errors.land_line }}</span>
              </label>
            </div>

          </Form>
        </div>

        <!-- Contacts starts -->
        <div v-if="customerToBeEdited.type === 'Business'" class="row px-2">

          <h4 class="titlePage titlePage_subtitle titlePage_green">Contacts</h4>

          <SingleContactTileComponent
            v-for="(contact,index) in customerToBeEdited.contacts"
            :key="index"
            :contact="contact"
            :contactTypes="contactTypes"
            :index="index"
            :states="state"
            @removeThisContact="(itemIndexToBeRemoved) => deleteContact(itemIndexToBeRemoved)"
            @updateThisContact="(itemIndexToBeUpdated) => $refs.updateContactModal.open(customerToBeEdited.contacts[itemIndexToBeUpdated],itemIndexToBeUpdated)"
          />

          <CreateNewCardComponent :title="'create new contact'"
                                  @createNewModalOpen="$refs.createNewContactModal.open()"/>

          <CreateNewContactModal ref="createNewContactModal"
                                 :contactTypes="contactTypes"
                                 @newContact="(dataReceived) => newContactToStore(dataReceived)"
          />
          <UpdateContactModal v-if="customerToBeEdited.contacts.length > 0" ref="updateContactModal"
                              :contactTypes="contactTypes"
                              @updatedContact="(dataReceived) => updateAContact (dataReceived)"

          />

        </div>
        <!-- Contacts ends -->

      </template>
      <template v-slot:actions>
        <button class="btn btn_transparent mx-0 mb-0" type="submit" form="edit_contact">
          <svg-icon name="solid/floppy-disk"/>
          Update
        </button>

      </template>
    </base-modal>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import store from "@/store/store.js";
import SingleContactTileComponent from "../../components/customer/SingleContactTileComponent";
import CustomerAPI from '@/routes/Customer/CustomerAPI.js'
import CreateNewCardComponent from '/src/components/commons/CreateNewCardComponent'
import CreateNewContactModal from "./CreateNewContactModal";
import UpdateContactModal from "./UpdateContactModal"
import GlobalMixins from "../../Mixins/GlobalMixins";
import { maska } from 'maska'

export default {
  name: "EditCustomerModal",
  directives: {
    maska
  },
  data() {
    return {
      show: false,
      customerToBeEdited: {},
      sameAsBusinessName: false,
      sameAsAboveBusinessAddress: true,
      billing_address: {
        address: '',
        suburb: '',
        postcode: '',
        state: '',
      },
      contactTypes: [],
    }
  },
  components: {
    SingleContactTileComponent,
    CreateNewCardComponent,
    CreateNewContactModal,
    UpdateContactModal
  },
  mixins : [GlobalMixins],
  methods: {
    async showModal(customerToBeEdited) {
      if (!this.state) store.dispatch('registration/getState')
      await this.getActiveContactTypes()
      this.customerToBeEdited = customerToBeEdited
      let edit = this.customerToBeEdited
      if (edit.business_name === edit.trading_name) this.sameAsBusinessName = true
      if (edit.billing_address && !edit.billing_address.is_same) {
        this.billing_address = edit.billing_address
        this.billing_address.state = edit.billing_address.state.state_id
        this.sameAsAboveBusinessAddress = false
      }
      if (edit.state && edit.state.state_id) this.customerToBeEdited.state = edit.state.state_id

      this.show = true
    },
    async getActiveContactTypes() {
      this.startLoader()
      this.contactTypes = (await CustomerAPI.activeContactTypes()).data.data
      this.stopLoader()
    },
    async deleteContact(itemIndexToBeRemoved) {

      this.startLoader()
      try {
        (await CustomerAPI.dealerCustomerContactDelete(this.customerToBeEdited.id, this.customerToBeEdited.contacts[itemIndexToBeRemoved].id)).data.data
        this.customerToBeEdited.contacts.splice(itemIndexToBeRemoved, 1)
      } catch (error) {
        this.showErrorModal(error)
        this.stopLoader()
      }
      this.stopLoader()
    },
    async newContactToStore(newContactDetails) {
      this.startLoader()
      try {
        await CustomerAPI.dealerCustomerContactCreat(this.customerToBeEdited.id, newContactDetails)
        this.customerToBeEdited.contacts = (await CustomerAPI.getDealerSingleCustomer(this.customerToBeEdited.id)).data.data.contacts
      } catch (error) {
        this.showErrorModal(error)
        this.stopLoader()
      }
      this.stopLoader()
    },
    async updateAContact(updatedContactDetails) {
      this.startLoader()
      try {
        await CustomerAPI.dealerCustomerContactUpdate(this.customerToBeEdited.id, updatedContactDetails.id, updatedContactDetails)
        this.customerToBeEdited.contacts = (await CustomerAPI.getDealerSingleCustomer(this.customerToBeEdited.id)).data.data.contacts
      } catch (error) {
        this.showErrorModal(error)
        this.stopLoader()
      }
      this.stopLoader()
    },
    async handleContact() {
      this.startLoader()
    /*  this.$validator.validate().then(async valid => {
        if (valid) {*/
          try {
            let payload = this.customerToBeEdited

            if (payload.billing_address && payload.billing_address.is_same) {
              delete payload.billing_address
            }
            delete payload.dealer
            //   delete payload.contacts

            await CustomerAPI.updateACustomer(payload.id, payload)
            this.showSuccessModal('Customer updated successfully.')
            this.$emit('onCustomerUpdateSuccess');
            this.show = false

          } catch (errors) {

            if (errors.response.status === 422) {
             /* console.log(123)
              _.forEach(errors.response.data.errors, (error, index) => {
                this.$validator.errors.add({
                  field: index,
                  msg: error[0]
                });
              })*/
            }
            this.showErrorModal(errors)
            this.stopLoader()
          }
       /* }*/
        this.stopLoader()
     /* });*/
    },
  },
  computed: {
    ...mapGetters({
      "state": 'registration/state',
    }),
  },
}
</script>

<style scoped>

</style>
