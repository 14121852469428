<template>
  <div>
    <base-modal
      v-model:show="show"
      :title="'New Customer'"
      size="lg"
    >
      <template v-slot:modal-content>
        <span class="text text_italic required__text">*Required Fields</span>
        <div class="row pb-2">
          <Form
            v-slot="{ errors }"
            ref="createForm"
            autocomplete="off"
            id="add_customer"
            class="form form_col"
            @submit="handleStatus"
          >
            <div class="col-12 col-md-6">
              <h4 class="titlePage titlePage_subtitle titlePage_green">Customer type</h4>
              <label class="form__label">
                <div class="form__wrapRadio">
                  <label class="form__label form__label_row">
                    <input
                      v-model="newCustomer.type"
                      class="form__radio"
                      hidden
                      name="type"
                      type="radio"
                      value="Individual"
                    >
                    <span class="form__radio_icon"/>
                    <span class="form__labelTitle">Individual</span>
                  </label>
                  <label class="form__label form__label_row">
                    <input
                      v-model="newCustomer.type"
                      class="form__radio"
                      hidden
                      name="type"
                      type="radio"
                      value="Business"
                    >
                    <span class="form__radio_icon"/>
                    <span class="form__labelTitle">Business</span>
                  </label>
                </div>
              </label>

              <!-- Individual customer form inputs starts -->
              <div v-show="newCustomer.type === 'Individual'">
                <label class="form__label">
                  <span class="form__labelTitle">First Name*</span>
                  <Field v-model="newCustomer.name"
                         :rules="{required:newCustomer.type === 'Individual', max:128}"
                         class="form__input" label="first name"
                         name="name"
                         placeholder="First Name*"
                         type="text"
                  />
                  <span class="form__error">{{ errors.name }}</span>
                </label>
                <label class="form__label">
                  <span class="form__labelTitle">Last Name*</span>
                  <Field v-model="newCustomer.last_name" :rules="{max:128}" class="form__input"
                         label="last name"
                         name="last_name"
                         placeholder="Last Name*"
                         type="text"
                  />
                  <span class="form__error">{{ errors.last_name }}</span>
                </label>
              </div>
              <!-- Individual customer form inputs ends -->

              <!-- Business customer form inputs starts -->
              <div v-show="newCustomer.type === 'Business'">
                <label class="form__label">
                  <span class="form__labelTitle">Business Name*</span>
                  <Field v-model="newCustomer.business_name"
                         :rules="{required:newCustomer.type === 'Business', max:128}"
                         class="form__input"
                         label="business name" name="business_name"
                         placeholder="Business Name*"
                         type="text"
                  />
                  <span class="form__error">{{ errors.business_name }}</span>
                </label>
                <label class="form__label">
                  <span class="form__labelTitle">Trading Name*</span>
                  <Field v-model="newCustomer.trading_name"
                         :rules="{required:newCustomer.type === 'Business', max:128}"
                         class="form__input"
                         label="trading name" name="trading_name"
                         placeholder="Trading Name*"
                         type="text"
                  />
                  <span class="form__error">{{ errors.trading_name }}</span>
                </label>

                <label class="label-switch switch-success">
                  <input id="status" v-model="sameAsBusinessName"
                         class="switch switch-bootstrap status" name="status" type="checkbox"
                         value="1">
                  <span class="lable"> Same as business name</span></label>

              </div>
              <!-- Business customer form inputs ends -->


              <!-- Address section starts -->
              <h4 class="titlePage titlePage_subtitle titlePage_green">Address</h4>

              <label class="form__label ">
                <span class="form__labelTitle">Address*</span>
                <Field as="textarea" v-model="newCustomer.address" :rules="{required: true, max: 255}"
                          class="form__input" name="address"
                          placeholder="Address*"
                          rows="2"
                />

                <span class="form__error">{{ errors.address }}</span>
              </label>

              <label class="form__label ">
                <span class="form__labelTitle">Suburb*</span>
                <Field v-model="newCustomer.suburb" :rules="{max: 128}" class="form__input"
                       name="suburb"
                       placeholder="Suburb*"
                       type="text"
                />
                <span class="form__error">{{ errors.suburb }}</span>
              </label>


              <label class="form__label ">
                <span class="form__labelTitle">Postcode*</span>
                <Field v-model="newCustomer.postcode"
                          :rules="'digits:4|valid_au_postcode'"
                          class="form__input"
                       v-maska="'####'" name="postcode"
                          placeholder="Postcode*"
                          type="text"
                />
                <span class="form__error">{{ errors.postcode }}</span>
              </label>

              <label class="form__label ">
                <span class="form__labelTitle">State*</span>
                <select v-model="newCustomer.state"
                        :style="opacity(newCustomer.state)"
                        class="form__input form__select"
                        name="state"
                >
                  <option :value="undefined" disabled hidden selected>State*</option>
                  <option
                    v-for="(item, index) in state"
                    :key="index"
                    :value="item.state_id"
                  >{{ item.name }}
                  </option>
                </select>

              </label>

              <label v-if="newCustomer.type === 'Business'" class="label-switch switch-success">
                <input id="status" v-model="sameAsAboveAddress"
                       class="switch switch-bootstrap status" name="status" type="checkbox"
                       value="1">
                <span class="lable"> Billing address is same as above</span></label>
              <!-- Address section ends -->

              <!--Billing Address section starts -->
              <transition name="fade">
                <div v-show="newCustomer.type === 'Business' && !sameAsAboveAddress">
                  <h4 class="titlePage titlePage_subtitle titlePage_green pt-2">Billing Address</h4>

                  <label class="form__label ">
                    <span class="form__labelTitle">Address*</span>
                    <Field as="textarea" v-model="billing_address.address"
                              :rules="{required: newCustomer.type === 'Business' && !sameAsAboveAddress, max: 255}"
                              class="form__input"
                              label="address"
                              name="billing_address"
                              placeholder="Address*"
                              rows="2"
                    />
                    <span class="form__error">{{ errors.billing_address }}</span>
                  </label>

                  <label class="form__label ">
                    <span class="form__labelTitle">Suburb*</span>
                    <Field v-model="billing_address.suburb" :rules="{max: 128}"
                           class="form__input" name="suburb"
                           placeholder="Suburb*"
                           type="text"
                    />
                    <span class="form__error">{{ errors.suburb }}</span>
                  </label>


                  <label class="form__label ">
                    <span class="form__labelTitle">Postcode*</span>
                    <Field v-model="billing_address.postcode"
                           :rules="'digits:4|valid_au_postcode'" class="form__input"
                           name="postcode"
                           placeholder="Postcode*"
                           type="text"
                    />
                    <span class="form__error">{{ errors.postcode }}</span>
                  </label>

                  <label class="form__label ">
                    <span class="form__labelTitle">State*</span>
                    <select v-model="billing_address.state"
                            :style="opacity(billing_address.state)"
                            class="form__input form__select"
                            name="state"
                    >
                      <option disabled hidden selected value="">State*</option>
                      <option
                        v-for="(item, index) in state"
                        :key="index"
                        :value="item.state_id"
                      >{{ item.name }}
                      </option>
                    </select>

                  </label>
                </div>
              </transition>
              <!--Billing Address section ends -->


            </div>

            <div class="col-12 col-md-6">
              <h4 class="titlePage titlePage_subtitle titlePage_green">Contact details</h4>

              <label class="form__label">
                <span class="form__labelTitle">E-mail</span>
                <Field v-model="newCustomer.email" :rules="'required|email'"
                       class="form__input"
                       name="email"
                       placeholder="E-mail"
                       type="text"
                />

                <span class="form__error">{{ errors.email }}</span>
              </label>

              <label class="form__label">
                <span class="form__labelTitle">Mobile Number*</span>
                <Field v-model="newCustomer.phone"
                          :rules="{required: true, digits_without_whitespace: 10, min: 6}"
                          class="form__input"
                          label="mobile number"
                          v-maska="'## ## ### ###'"
                          name="phone"
                          placeholder="Mobile Number*"
                />
                <span class="form__error">{{ errors.phone }}</span>
              </label>

              <label class="form__label">
                <span class="form__labelTitle">Landline Number</span>
                <Field v-model="newCustomer.land_line"
                          :rules="{digits_without_whitespace: 10, min: 6}"
                          class="form__input"
                          label="landline number"
                          v-maska="'## ## ### ###'"
                          name="land_line"
                          placeholder="Landline Number"
                />
                <span class="form__error">{{ errors.land_line }}</span>
              </label>
            </div>
          </Form>
        </div>

        <!-- Contacts starts -->
        <div v-if="newCustomer.type === 'Business'" class="row px-2 tw-mb-4">

          <h4 class="titlePage titlePage_subtitle titlePage_green">Contacts</h4>

          <SingleContactTileComponent v-for="(contact,index) in newCustomer.contacts"
                                      :key="index"
                                      :contact="contact"
                                      :contactTypes="contactTypes"
                                      :index="index"
                                      :states="state"
                                      @removeThisContact="(itemIndexToBeRemoved) => newCustomer.contacts.splice(itemIndexToBeRemoved, 1)"
                                      @updateThisContact="(itemIndexToBeUpdated) => $refs.updateContactModal.open(newCustomer.contacts[itemIndexToBeUpdated],itemIndexToBeUpdated)"
          />
          <div class="tw-grid md:tw-grid-cols-3 tw-gap-5">
          <CreateNewCardComponent :title="'create new contact'"
                                  @createNewModalOpen="$refs.createNewContactModal.open()"/>
            </div>

          <CreateNewContactModal ref="createNewContactModal"
                                 :contactTypes="contactTypes"
                                 @newContact="(dataReceived) => newCustomer.contacts.push(dataReceived)"
          />

          <UpdateContactModal v-if="newCustomer.contacts.length > 0" ref="updateContactModal"
                              :contactTypes="contactTypes"
                              @updatedContact="(dataReceived) => newCustomer.contacts.splice(newCustomer.contacts.indexOf(dataReceived.index), 1, dataReceived)"

          />

        </div>
        <!-- Contacts ends -->
      </template>

      <template v-slot:actions>
        <button class="btn btn_transparent mx-0 mb-0" form="add_customer">
          <svg-icon name="solid/floppy-disk"/>
          Save
        </button>

      </template>
    </base-modal>
  </div>
</template>

<script>

import {mapGetters} from 'vuex';
import CreateNewCardComponent from '/src/components/commons/CreateNewCardComponent'
import CreateNewContactModal from "./CreateNewContactModal";
import UpdateContactModal from "./UpdateContactModal"
import SingleContactTileComponent from "../../components/customer/SingleContactTileComponent";
import CustomerAPI from '@/routes/Customer/CustomerAPI.js'
import GlobalMixins from "../../Mixins/GlobalMixins";
import { maska } from 'maska'

export default {
  name: "AddNewCustomerPopup",
  directives: {
    maska
  },
  mixins : [GlobalMixins],
  data() {
    return {
      createNewContactModalVisibility: false,
      show: false,
      newCustomer: {
        type: 'Individual',
        contacts: []
      },
      billing_address: {
        address: '',
        suburb: '',
        postcode: '',
        state: '',
      },
      disableInputs: false,
      contactTypes: [],
      sameAsBusinessName: false,
      sameAsAboveAddress: true,
      toBeUpdatedIndex: 0,
      contactToBeUpdatedViaIndex: {}
    }
  },
  components: {
    CreateNewCardComponent,
    CreateNewContactModal,
    SingleContactTileComponent,
    UpdateContactModal
  },
  methods: {
    async updateContactViaIndex(index) {
      this.startLoader()
      this.toBeUpdatedIndex = index
      this.contactToBeUpdatedViaIndex = this.newCustomer.contacts[index]
      setTimeout(() => {
        this.$refs.updateContactModal.open(123)
        this.stopLoader()
      }, 3000)

    },
    async getActiveContactTypes() {
      this.startLoader()
      this.contactTypes = (await CustomerAPI.activeContactTypes()).data.data
      this.stopLoader()
    },
    showModal() {
      this.show = true
    },
    opacity(elem) {
      if (!elem) {
        return 'color: #B5B5B5';
      }
    },
    async handleStatus() {
      this.startLoader()
      /*this.$validator.validate().then(async valid => {
        if (valid) {*/
          try {
            if(!this.sameAsAboveAddress && this.billing_address && this.billing_address.address) {
              this.newCustomer.billing_address = this.billing_address
            }
            await CustomerAPI.addNewCustomer(this.newCustomer)
            this.showSuccessModal('Customer added successfully.')
            this.$emit('onCustomerStoreSuccess');
            this.show = false

          } catch (errors) {

           /* if (errors.response.status === 422) {
              _.forEach(errors.response.data.errors, (error, index) => {
                this.$validator.errors.add({
                  field: index,
                  msg: error[0]
                });
              })
            }*/
            this.showErrorModal(errors)
            this.stopLoader()
          }
       // }
        this.stopLoader()
     // });
    },
  },
  computed: {
    ...mapGetters({
      "state": 'registration/state',
    }),
  },
  watch: {
    show(value) {
      if (value) {
        this.newCustomer = {
          type: 'Individual',
          contacts: []
        }
        this.getActiveContactTypes()
        this.$store.dispatch('registration/getState');
      }
    },
    sameAsBusinessName(value) {
      if (value && this.newCustomer.business_name) {
        this.newCustomer.trading_name = this.newCustomer.business_name

        //Reset error
       /* const field = this.$validator.fields.find({name: 'trading_name'});
        field.reset();
        this.$validator.errors.remove(field.name, field.scope);*/
      }
    },
    newCustomer: {
      handler(newVal) {
        if (this.sameAsBusinessName) this.newCustomer.trading_name = newVal.business_name
      },
      deep: true,
      immediate: true
    }
  }

}
</script>

<style lang="scss" scoped>

@import "src/assets/scss/utils/vars";

.customer_list {
  max-height: 50vh;
  overflow-y: scroll;
}

.customer {
  padding: 5px 0;
  cursor: pointer;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}

</style>
