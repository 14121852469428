<template>
  <div>
    <div
      class="
        tw-bg-secondary
        tw-grid
        tw-grid-cols-1
        md:tw-grid-cols-2
        tw-p-3 tw-gap-4
        tw-m-x-0
      "
    >
      <div>
        <label class="form__label tw-m-0 tw-py-1.5">
          <input
            v-model="search"
            class="form__input"
            name="query"
            placeholder="Search anything"
            type="search"
            @input="updateFilterQuery"
          >
        </label>
      </div>
      <div
        class="
          tw-flex tw-gap-1 tw-items-center tw-justify-self-end tw-flex-wrap
        "
      >
        <label class="form__label  tw-m-0 tw-w-max ">
          <a
            class="tw-text-xl
            tw-text-white
            tw-border
            tw-uppercase
            tw-font-bolder
            tw-text-xs
          "
            href="#"
            @click="$emit('openAddNewModal')"
          >
            <svg-icon name="plus"
              class="p-1 tw-text-secondary"
              style="background-color:white;border-radius:50%"
            />
            new customer
          </a>
        </label>

        <label class="form__label tw-m-0 tw-w-max">
          <select
            v-model="groupBy"
            class="
              form__input
              tw-rounded-full tw-text-xs tw-px-1.5 tw-py-1.5 tw-w-max tw-border
            "
            name="groupBy"
          >
            <option value="none">Group By: None</option>
            <option value="suburb">Group By: Suburb</option>
          </select>
        </label>

        <label class="form__label tw-m-0 tw-w-max">
          <select
            class="
              form__input
              tw-rounded-full tw-text-xs tw-px-1.5 tw-py-1.5 tw-w-max tw-border
            "
            name="groupBy"
          >
            <option
              select
              value="none"
            >Sort By By: name
            </option>
          </select>
        </label>
        <label class="form__label  tw-m-0 tw-w-max">
          <select
            v-model="perPage"
            class="
              form__input
              tw-rounded-full tw-text-xs tw-px-1.5 tw-py-1.5 tw-w-max tw-border
            "
            name="groupBy"
          >
            <option value="10">Show : 10</option>
            <option value="20">Show : 20</option>
            <option value="50">Show : 50</option>
            <option value="100">Show : 100</option>
            <option value="200">Show : 200</option>
          </select>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      search: '',
      perPage: 10,
      groupBy: 'none'
    }
  },
  watch: {
    search(value) {
      if (value === '') this.$emit('updatedSearchValue', value)
    },
    perPage(value) {
      this.$emit('updatedPagination', value)
    },
    groupBy(value) {
      this.$emit('updateGroupBy', value)
    }
  },
  methods: {
    updateFilterQuery: _.debounce(function debounceRead() {
      this.$emit('updatedSearchValue', this.search)
    }, 500),
    updateQueryParams(params) {
      this.search = params.search;
      this.perPage = params.pagination;
      this.groupBy = params.groupBy;
    }
  }
}
</script>

<style scoped>

</style>
