<template>
  <div class="container-fluid tw-bg-white tw-sticky tw-top-0" style="z-index: 1;">
    <Sorting
      title="Customers"
      placeholder="Customer Search"
      :sorting="sorting"
      :is-created="true"
      @sorting="sortCustomers"
      @search="(v) => routerQuery.search = v"
      @create="showCustomerModal = true"
    >
      <template #extra-actions>
        <div class="tw-mx-3">
          <div>
            <Popover v-slot="{ open }" class="popover">
              <PopoverButton as="button" class="btn btn_default popover-button">
                <div class="tw-space-x-1.5">
                  <span>Group By: {{ routerQuery.groupBy }} </span>
                </div>
                <DropDownChevron :is-open="open"></DropDownChevron>
              </PopoverButton>

              <transition name="popover">
                <PopoverPanel v-slot="{ close }" class="popover-panel tw-w-28">
                  <div class="tw-grid tw-grid-cols-1 tw-gap-1 tw-text-sm">
                    <button
                      class="btn btn_default popover-menu-item tw-capitalize"
                      :class="{ 'popover-menu-item-active': routerQuery.groupBy === 'none' }"
                      @click="
                        () => {
                          routerQuery.groupBy = 'none';
                          close();
                        }
                      "
                    >
                      None
                    </button>
                    <button
                      class="btn btn_default popover-menu-item tw-capitalize"
                      :class="{
                        'popover-menu-item-active': routerQuery.groupBy === 'suburb',
                      }"
                      @click="
                        () => {
                          routerQuery.groupBy = 'suburb';
                          close();
                        }
                      "
                    >
                      Suburb
                    </button>
                  </div>
                </PopoverPanel>
              </transition>
            </Popover>
          </div>      
        </div>
      </template>
    </Sorting>
  </div>
  <div class="container- px-0">
    <div v-if="customers.length > 0" class="row">
      <GroupedByView
        v-if="routerQuery.groupBy !== 'none'"
        :customers="customers"
        @updateThisCustomer="(idOfTheCustomerToBeEdited) => getCustomerToBeEdited(idOfTheCustomerToBeEdited)"
      />
      <div v-else class="container">
        <div class="tw-px-4- tw-grid tw-grid-cols-1
            sm:tw-grid-cols-2
            md:tw-grid-cols-3
            tw-gap-4">

          <div v-for="customer in customers" :key="customer.id">
            <CustomerCard 
              :customer="customer" 
              @updateThisCustomer="(idOfTheCustomerToBeEdited) => getCustomerToBeEdited(idOfTheCustomerToBeEdited)"
            />

          </div>
        </div>
      </div>

    </div>

    <div v-else-if="isMounted" class="row container">
      <div
        class="col-12 tw-text-xl tw-text-secondary tw-text-center tw-cursor-pointer tw-p-3 tw-mb-3.5"
      >
        No customers to display.
      </div>
    </div>
    
    <div class="container">
      <div v-if="pagination.has_more_pages"
          class="load_more tw-text-center tw-cursor-pointer tw-p-3 tw-mb-3.5"
          @click="loadMore()">
          <div class="col-12">
           Load more...
          </div>
       
      </div>
    </div>
    <AddNewCustomerPopup ref="addNewModal" @onCustomerStoreSuccess="getCustomers()"/>
    <EditCustomerModal ref="editModal" @onCustomerUpdateSuccess="getCustomers()"/>
  </div>

  <Teleport to="body">
    <Modal class="change_customer_modal kirubamod" v-if="showCustomerModal" :modelValue="showCustomerModal"
          @update:modelValue="customerToBeEdited = false, showCustomerModal = false">
      <div class="change_customer_modal modal-content">
          <CustomerForm :selected="customerToBeEdited" :customer-id="customerToBeEdited" @customerSaved="onCustomerSaved" :showBackButton="false" @back="showCustomerModal = false, customerToBeEdited = false"/>
      </div>
    </Modal>
  </Teleport>
  
</template>

<script>
import CustomerAPI from '@/routes/Customer/CustomerAPI.js'
import SingleCustomerTileComponent from '../../components/customer/SingleCustomerTileComponent'
import Header from '../../components/customer/Header'
import GroupedByView from "../../components/customer/GroupedByView";
import AddNewCustomerPopup from "./AddNewCustomerPopup"
import EditCustomerModal from "./EditCustomerModal";

import GlobalMixins from "../../Mixins/GlobalMixins";
import Sorting from "@/components/sorting/Sorting";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";
import CustomerCard from '../../components/customer/CustomerCard.vue'
import Modal from '@/components/item-wizard/components/elements/Modal'
import CustomerSelector from '../../components/customer/CustomerSelector.vue';
import CustomerForm from '../../components/customer/CustomerForm.vue';
import { notify } from "@kyvg/vue3-notification"

export default {
  name: "CustomerList",
  data() {
    return {
      showCustomerModal: false,
      customerToBeEdited: null,
      show: false,
      customers: [],
      pagination: {},
      isMounted: false,
      routerQuery: {
        page: 1,
        pagination: 9,
        search: '',
        groupBy: 'none',
        sort: undefined
      },
      sorting: [
      {
        name: 'By name',
        sort: 'name',
        value: true,
      },
      
    ]
    }
  },
  mixins : [GlobalMixins],
  components: {
    Header,
    GroupedByView,
    SingleCustomerTileComponent,
    AddNewCustomerPopup,
    EditCustomerModal,
    Sorting,
    Popover,
    PopoverButton,
    PopoverPanel,
    CustomerCard,
    CustomerSelector,
    Modal,
    CustomerForm
},
  async mounted() {
    this.isMounted = false
    /*if (localStorage.getItem('customerRouteQuery')) {
      this.routerQuery = JSON.parse(localStorage.getItem('customerRouteQuery'))
      if (this.routerQuery.page > 1) {
        for (let i = 1; i <= this.routerQuery.page; i++) {
          await this.getCustomers(i)
        }
      } else {
        await this.getCustomers()
      }
      this.$refs.header.updateQueryParams(this.routerQuery)
    } else {*/
      await this.getCustomers()
//    }

    setTimeout(() => {
      this.isMounted = true
    }, 2000)

  },
  methods: {
    onCustomerSaved() {
      this.showCustomerModal = false
      this.getCustomers()

      notify({
        type: 'success',
        title: this.customerToBeEdited? "Customer updated successfully.": 'Customer added successfully.'
      })
      this.customerToBeEdited = null

    },
    sortCustomers(sort, v) {
      this.routerQuery.sort = {
        name: sort.name,
        sort: sort.sort,
        value: v,
      }

      console.log('Sort Csuo', sort)
      console.log('Sort val', v)
    },
    async getCustomers(page = 1) {

     this.startLoader()
      let query = this.routerQuery

      let response = (await CustomerAPI.getDealerCustomers(page, query.pagination, query.search, query.groupBy, query.sort? `${query.sort.value? '': '-'}${query.sort.sort}`: undefined )).data

      if (this.routerQuery.groupBy !== 'none') this.customers = _.map(_.entries(response.data), ([k, v]) => ({[k]: v}))
      else this.customers = response.data
      this.pagination = response.pagination

      if (localStorage.getItem('customerRouteQuery')) {
        let updatedPageQuery = JSON.parse(localStorage.getItem('customerRouteQuery'))
        updatedPageQuery.page = 1
        localStorage.setItem('customerRouteQuery', JSON.stringify((updatedPageQuery)))
      }

      this.stopLoader()
    },
    async loadMore() {

     this.startLoader()

      let response = (await CustomerAPI.getDealerCustomers(this.pagination.current_page + 1, this.routerQuery.pagination, this.routerQuery.search, this.routerQuery.groupBy)).data
      if (this.routerQuery.groupBy !== 'none') response.data = _.map(_.entries(response.data), ([k, v]) => ({[k]: v}))

      response.data.forEach((customer) => {
        this.customers.push(customer)
      })

      this.pagination = response.pagination

      if (localStorage.getItem('customerRouteQuery')) {
        let updatedPageQuery = JSON.parse(localStorage.getItem('customerRouteQuery'))
        updatedPageQuery.page = this.pagination.current_page

        localStorage.setItem('customerRouteQuery', JSON.stringify((updatedPageQuery)))
      }

      this.stopLoader()
    },
    async getCustomerToBeEdited(customerId) {
     this.startLoader()
      try {
        // let customerToBeEdited = (await CustomerAPI.getDealerSingleCustomer(customerId)).data.data
        this.customerToBeEdited = customerId
        this.showCustomerModal = true
        // this.$refs.editModal.showModal(customerToBeEdited)

      } catch (error) {
        this.showErrorModal(error)
        this.stopLoader()
      }
      this.stopLoader()
    }

  },
  watch: {
    routerQuery: {

      handler(newVal) {
        if (this.isMounted) {
          this.getCustomers()
          localStorage.setItem('customerRouteQuery', JSON.stringify(newVal))
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style scoped>

.change_customer_modal.modal-content {
  max-height: 85vh;
  overflow: auto;
}

::v-deep(.modal__content__wrapper ) {
  width: 600px;
  max-width: 100%;
}

.load_more {

  border: 2px #55b494 solid;
  font-size: 1.2em;
}
</style>
