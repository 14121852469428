<template>
  <div
    class="col-lg-4 col-sm-6 col-12 tw-text-sm tw-cursor-pointer"
    @click="$router.push({'name':'customers.show',params: { id : customer.id }})"
  >
    <div
      class="orderItem tw-relative tw-border-black "
    >
      <h2
        class="orderItem__title tw-bg-draft"
      >
        <span class="tw-inline-block tw-flex tw-items-center tw-space-x-2  tw-truncate">
          <span>
            {{ customer.name }}
          </span>
        </span>
        <span
          class="orderItem__iconRight"
          @click.stop="$emit('updateThisCustomer',customer.id)"
        >
          <span class="tw-inline-block tw-h-4 mt-1">
            <svg-icon name="pen" />
          </span>
        </span>
      </h2>

      <div class="orderItem__boxInfo tw-grid  tw-space-x-2 tw-font-helvetica tw-overflow-hidden ">
        <div class="tw-space-y-0">
          <ul
            class="pl-1 tw-list-disc tw-list-inside"
          >
            <li>
              Date created : {{  $filters.formatDate(customer.created_at) }} <span
                class="pr-1"
                style="float: right;font-size:12px"
              >{{ customer.type }}</span>
            </li>
            <li>Address : {{ getCustomerFullAddress(customer) }}</li>
            <li>Phone : {{ customer.phone ? customer.phone : 'N/A' }}</li>
            <li>Email : {{ customer.email }}</li>
            <li>Number of orders: {{ customer.number_of_orders }}</li>
            <li>Total value: {{ getValueInAU(customer.total_value) }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GlobalMixins from "../../Mixins/GlobalMixins";
export default {
  name: "SingleCustomerTileComponent",
  props: {
    customer: {
      required: true,
      type: Object,
    }
  },
  mixins : [GlobalMixins],
}
</script>

<style scoped>
.orderItem__boxInfo {
  min-height:165px;
}
</style>
