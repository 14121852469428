<template>
  <div class="card" :class="noButton ? 'card--no_button': ''">
    <div class="card__header tw-text-black-gray">
      <h5 class="header__id">{{ customer.display_name }}</h5>
      
    </div>
    <div class="card__content">
      <div class="content__elements">
        <div class="d-flex align-items-center justify-content-end">
          <div class="d-flex align-items-center" style="font-weight: bold">
            <svg-icon name="solid/clock"/>
            {{dateFormat(customer.created_at)}}
          </div>
        </div>
        <div
          class="d-flex align-items-center tw-space-x-2"
        >
          <svg-icon name="solid/cog"/>
          <span>{{ getCustomerFullAddress(customer) }}</span>
        </div>

        <div class="tw-flex tw-items-center tw-space-x-2">
          <svg-icon name="solid/phone" />
          <span>Phone: {{ customer.phone || "N/A" }} </span>
        </div>

        <div class="tw-flex tw-items-center tw-space-x-2">
          <svg-icon name="solid/envelope" />
          <span>Email: {{ customer.email }}</span>
        </div>

        <div class="tw-flex tw-items-center tw-space-x-2">
          <svg-icon name="solid/copy" />
          <span>Number of orders: {{ customer.number_of_orders }}</span>
        </div>

        <div class="tw-flex tw-items-center tw-space-x-2">
          <svg-icon name="solid/dollar" />
          <span>Total Value: {{ getValueInAU(customer.total_value) }}</span>
        </div>

        <ButtonMain @click.stop="$emit('updateThisCustomer',customer.id)">
          <svg-icon name="solid/edit"/>
          Edit
        </ButtonMain>

      </div>
      </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import ButtonMain from "@/components/buttons/ButtonMain";
import SimpleDropdown from "@/components/dropdown/SimpleDropdown";
import {changePriceForm} from '../../helpers/aroundPrice';
import {formatDate, dateCalcFormat} from "../../helpers/datetime";
import GlobalMixins from "../../Mixins/GlobalMixins";

export default {
  name: 'CustomerCard',
  props: ['customer'],
  mixins : [GlobalMixins],
  components: {
    ButtonMain,
    SimpleDropdown,
  },
  setup(props, {emit}) {
    const menuIsOpened = ref(false)

    let toggleMenu = () => {
      menuIsOpened.value =! menuIsOpened.value
    }
    let closeMenu = () => {
      menuIsOpened.value = false
    }
    let priceCalc = (price) => {
      return changePriceForm(price);
    }
    let dateCalc = (date) => {
      if(formatDate(date)) {
        return formatDate(date);
      } else
      return date
    }
    let dateFormat = (date) => {
      return dateCalcFormat(date)
    }

    return {
      toggleMenu,
      menuIsOpened,
      closeMenu,
      priceCalc,
      dateCalc,
      dateFormat,
    }
  }
};
</script>


<style scoped lang="scss">
@import "../../../src/assets/scss/utils/vars";
.card {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0px 3px 3px rgba(28, 40, 51, 0.2);
  border-radius: 2px;
  height: auto;
  margin-bottom: 10px;
  &__header {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: rgba(11, 53, 83, 0.15);
    border-radius: 2px 2px 0px 0px;
    .header {
      &__id {
        font-size: 13px;
        margin-left: 10px;
        margin-right: 5px;
        text-transform: uppercase;
        flex-shrink: 0;
        font-weight: bold;
      }
      &__title {
        font-size: 13px;
        text-transform: uppercase;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        color: rgba(28, 40, 51, 0.7);
        font-weight: bold;
      }
      &__info {
        height: 100%;
        width: 30px;
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        background: rgba(11, 53, 83, 0.2);
        margin-left: auto;
        font-size: 14px;
        color: white;
        cursor: pointer;
        transition-duration: 0.2s;
        &:hover {
          background: #59B692;
        }
      }
    }
    ::v-deep .dropdown_menu {
      flex-shrink: 0;
      margin: 0;
      background: linear-gradient(0deg, rgba(11, 53, 83, 0.15), rgba(11, 53, 83, 0.15)), #FFFFFF;
      &:hover {
        background: linear-gradient(0deg, rgba(11, 53, 83, 0.2), rgba(11, 53, 83, 0.2)), #FFFFFF;
      }
      .simple_dropdown {
        > * {
          transition-duration: 0.2s;
          &:hover {
            span {
              transition-duration: 0.2s;
              color: #59B692;
            }
            color: #59B692;
          }
        }
        div.cancel {
          &:hover {
            span {
              color: #C0392B;
            }
            color: #C0392B;
          }
        }
      }
    }
  }
  &__content {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 10px 10px 7px;
    color: rgba(28, 40, 51, 0.8);
    font-size: 13px;
    span {
      margin-right: 5px;
    }
    .content {
      &__elements {
        & > div {
          padding-bottom: 10px;
        }
        & div {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        .elements {
          &__reference {
            font-weight: bold;
            font-size: 13px;
            color: #1C2833;
            padding-bottom: 14px;
            &--inactive {
              color: rgba(28, 40, 51, 0.6);
              font-style: italic;
              font-weight: normal;
            }
          }
        }
      }
      &__button {
        margin-top: auto;
        button {
          margin-bottom: 10px;
        }
      }
    }
  }
  &--no_button {
    height: 160px;
    .card__content {
      padding-bottom: 0;
    }
  }
}
@media (max-width: 768px) {
  ::v-deep .dropdown_menu {
    .simple_dropdown {
      padding: 0;
      width: 260px;
      > * {
        padding: 0 18px;
        height: 50px;
        &:not(:last-child) {
          border-bottom: 1px solid rgba(11, 53, 83, 0.2);
        }
      }
    }
  }
}
</style>
