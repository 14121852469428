<template>
  <div
    :key="key"
    class="col-12"
  >
    <div
      v-for="(singleCustomerGroup,index) in formattedCustomers"
      :key="index"
    >
      <div class="tw-relative" :class="index === 0? 'tw-mb-8': 'tw-my-8'">
          <div class="tw-h-0.5 tw-bg-gray-300 tw-w-full tw-absolute tw-top-1/2 tw-z-0"></div>
          <div class="container">
            <span class=" tw-bg-white tw-relative tw-px-4 tw--ml-4 group-title">
              <span v-if="singleCustomerGroup">
              {{ singleCustomerGroup.key }}
            </span>
            </span>
          </div>        
      </div>
      
      <div
        class="orderItem- tw-relative  "
      >

        <div class="orderItem__boxInfo- tw-grid  tw-space-x-2 tw-font-helvetica">
          <div class="tw-space-y-0">
            <div
              class="row container"
            >

              <div class="tw-grid tw-grid-cols-1
                sm:tw-grid-cols-2
                md:tw-grid-cols-3
                tw-gap-4">

              <div v-for="(singleCustomer,singleCustomerIndex) in itemsToBeShown(singleCustomerGroup)" :key="singleCustomer.id">
                <CustomerCard 
                  :customer="singleCustomer"
                  @updateThisCustomer="(idOfTheCustomerToBeEdited) => $emit('updateThisCustomer',idOfTheCustomerToBeEdited)"
                />

              </div>
            </div>

      
            </div>
            <div class="container tw-mx-auto">
              <div
                v-if="showMoreButtonVisibility(singleCustomerGroup)"
                class="col-12 load_more"
                @click="showMore(index)"
              >
                Show more...
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SingleCustomerTileComponent from './SingleCustomerTileComponent'
import CustomerCard from './CustomerCard.vue'
export default {
  name: "GroupedByView",
  components: {
    SingleCustomerTileComponent, CustomerCard
  },
  props: {
    customers: {
      required: true,
      type: Array,
    }
  },
  data() {
    return {
      key: 1
    }
  },
  computed: {
    formattedCustomers() {
      return this.customers.map((customer) => {
        return Object.keys(customer).map(key => ({key, value: customer[key]})).reduce(() => {
        })
      })
    }
  },
  methods: {
    showMoreButtonVisibility(singleCustomerGroup) {
      if(!singleCustomerGroup) return
      if (singleCustomerGroup.show_more) {
        return singleCustomerGroup.show_more !== singleCustomerGroup.value.length
      }
      return singleCustomerGroup.value.length > 3
    },
    itemsToBeShown(singleGroup) {
      if(!singleGroup) return
      return singleGroup.value.slice(0, (singleGroup.show_more) ? singleGroup.show_more : 3)
    },
    showMore(index) {
      if (this.formattedCustomers[index].show_more) {
        let deduct = (this.formattedCustomers[index].value.length - this.formattedCustomers[index].show_more)
        let numberOfItemsToShow = deduct > 3 ? 3 : deduct
        this.formattedCustomers[index].show_more += numberOfItemsToShow
      } else {
        let deduct = this.formattedCustomers[index].value.length - 3
        let numberOfItemsToShow = deduct > 3 ? 3 : deduct
        this.formattedCustomers[index].show_more = 3 + numberOfItemsToShow
      }

      this.key++
    }
  }
}
</script>

<style scoped>

.group-title {
    text-transform: uppercase;
   font-weight: bold;
   font-size: 21px;
   line-height: 100%;
   text-transform: uppercase;
   position: relative;
   color: rgba(11, 53, 83, 0.5);
}
.load_more {
  text-align: center;
  border: 2px #55b494 solid;
  font-size: 1.2em;
  margin-bottom: 25px;
  padding: 10px 0;
  cursor: pointer;
}
</style>

